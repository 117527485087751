const routes = [
    {
        path: 'login',
        name: 'login',
        component: () => import(`@/views/Authorization/Auth`)
    },
    {
        path: 'registration',
        name: 'registration',
        component: () => import(`@/views/Authorization/Registration/Page`)
    },
    {
        path: 'forgot-password',
        name: 'forgotPassword',
        component: () => import(`@/views/Authorization/ForgotPassword/ForgotEmail`)
        // component: () => import(`@/views/Authorization/ForgotPassword/Page`)
    },
    {
        path: 'reset-password/:id',
        name: 'resetPassword',
        component: () => import(`@/views/Authorization/ResetPassword/Page`)
    },
    {
        path: 'forgot-password/forgot-email',
        name: 'forgotPasswordEmail',
        component: () => import(`@/views/Authorization/ForgotPassword/ForgotEmail`)
    },
    {
        path: 'forgot-password/forgot-phone',
        name: 'forgotPasswordPhone',
        component: () => import(`@/views/Authorization/ForgotPassword/ForgotPhone/index`)
    },
    {
        path: 'join-user',
        name: 'joinUser',
        component: () => import(`@/views/Authorization/Auth`)
    },
]

export default routes
