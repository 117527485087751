export default () => ({
    filterList: [],
    filterStatus: {},
    filterData: {},
    filterSelected: {},
    filterTags: {},
    filterActive: {},
    filterExclude: {},
    filter: {},
    dataFilters: {},
    filtersSearch: {},
    filterOrdering: {},
    filterLoading: {},
    filterShowSearch: {}
})
