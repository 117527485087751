export default () => ({
    user: null,
    usersAuth: [],
    settingVisible: false,
    defaultTabKey: '1',
    profileMenu: [],
    onlineUsers: [],
    offlineUser: [],
    firstOnlineCheck: [],
    regStep: 1,
    authConfig: null
})